<template>
  <div class="main-container container">
    <div class="row">
      <div class="col-12 px-md-5">
        <h1 style="font-weight: 700;">About Sastra</h1>
        <p>
          At SASTRA, we would like to see a significant transformation in the
          modern war fronts with technological advancements using highly skilled
          human resource. Under RRU, we are already moulding talented personnel
          for Police and allied services.
        </p>
        <p>
          The concept of SASTRA is to set an exemplary lead in the indigenous
          design and development of high quality security goods. Including all
          several products affiliated to internal security.
        </p>
      </div>
      <div class="col-12">
        <img src="@/assets/img/about.jpg" class="img-fluid" alt="HGV" />
      </div>
      <div class="col-12 my-5 px-md-5">
        <h1 style="font-weight: 700;">The Team</h1>
        <h3>The Advisory</h3>
        <p>
          The Directors who hold places on decision making committees are some
          of the brightest minds in their respective fields. They have been
          highly ranked servicemen, police officers, lawmakers and lawyers among
          other professions & service.
        </p>

        <h3>The Leadership</h3>
        <p>
          Our leadership rests in the way that our teammates act as leaders in
          their own aspects. It is a mindset that could be developed. Apart from
          getting the job done, our team tends to go beyond bounds to achieve
          goals.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
